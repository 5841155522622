<template>
  <div class="c-input-date inputDateRangePicker">
    <CInputGroup id="inputDateRange" :style="{ maxWidth: '300px' }">
      <DatePicker
        ref="picker"
        is-range
        :class="{ inputDateRange: hasClosed }"
        :model-value="modelValue"
        :masks="{ input: format.date }"
        @update:modelValue="selected"
      >
        <template #default="{ inputValue, inputEvents }">
          <CFormInput
            style="width: 100%"
            :value="`${inputValue.start || 'Start Date'} - ${
              inputValue.end || 'End Date'
            }`"
            :placeholder="placeholder"
            v-on="getConfig(inputEvents)"
          />
        </template>
      </DatePicker>
    </CInputGroup>
    <CButton
      v-if="hasClosed"
      style="display: none"
      class="clearDateRange"
      variant="outline"
      color="secondary"
    >
      <CIcon name="cil-x" @click="clear" />
    </CButton>
  </div>
</template>

<script>
import { Calendar, DatePicker } from 'v-calendar'

export default {
  components: {
    Calendar,
    DatePicker,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {},
    handleOn: { type: String, default: 'hover' },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      format: {
        date: process.env.VUE_APP_DATE_FRONT_FORMAT,
        time: process.env.VUE_APP_TIME_FRONT_FORMAT,
      },
    }
  },
  computed: {
    hasClosed() {
      return this.modelValue && (this.modelValue.start || this.modelValue.end)
    },
  },
  mounted() {
    this.$refs.picker.$el.style.width = '100%'
    this.$refs.picker.$el.style.display = 'flex'
    this.$refs.picker.$el.style.alignItems = 'center'
  },
  methods: {
    selected(value) {
      this.$emit('update:modelValue', value)
    },
    clear() {
      this.$emit('update:modelValue', {})
    },
    getConfig(inputEvents) {
      return this.handleOn === 'click'
        ? {
            focus: inputEvents.start.mousemove,
            blur: inputEvents.start.mouseleave,
          }
        : {
            mousemove: inputEvents.start.mousemove,
            mouseleave: inputEvents.start.mouseleave,
          }
    },
  },
}
</script>

<style lang="scss">
.clearDateRange {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;
}

.inputDateRangePicker:hover .clearDateRange {
  display: block !important;
}

.inputDateRangePicker:hover .inputDateRange input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

//.inputDateRange input {
//  border-top-right-radius: 0;
//  border-bottom-right-radius: 0;
//  border-right: none;
//}

//.clearDateRange {
//  border-top-left-radius: 0 !important;
//  border-bottom-left-radius: 0 !important;
//  border-left: none !important;
//}

.c-input-date {
  width: 100%;
  display: flex;
  align-items: center;

  &__input input {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    border: 1px solid;
    color: #5c6873;
    background-color: #fff;
    border-color: #e4e7ea;
    border-radius: 0.25rem;

    &:focus-visible {
      border-color: #8ad4ee;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
    }
  }
}
</style>
