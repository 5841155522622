<template>
  <div class="activity">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Activity </CBreadcrumbItem>
    </teleport>

    <CCard>
      <CCardBody>
        <div
          class="d-flex align-items-center justify-content-between mb-2 w-100 flex-wrap flex-sm-nowrap"
        >
          <div class="d-block d-sm-flex w-100">
            <CInputGroup :style="{ width: '300px' }" class="mr-2 mb-2 w-sm-100">
              <CInputGroupText><CIcon name="cil-search" /></CInputGroupText>
              <CFormInput
                v-model="search"
                placeholder="Search"
                @blur="fetchData"
              />
            </CInputGroup>
          </div>

          <CButton color="primary" class="mb-2 w-sm-100" @click="exportData">
            Export
          </CButton>
        </div>
        <SmartTable
          :items="activity"
          :items-per-page="parseInt(perPage)"
          :columns="fields"
          :sorter-value="sort"
          :loading="loadingTable"
          :active-page="activePage"
          :per-page="perPage"
          :pages="pages"
          :clear-text="
            search.length ? 'No search results found' : 'No Data yet'
          "
          @sorter-change="onSort"
          @update-active-page="(value) => (activePage = value)"
          @update-per-page="(value) => (perPage = value)"
        >
          <template #project="{ item }">
            <td>
              <router-link
                v-if="item.project"
                style="text-decoration: underline"
                :to="{
                  name: 'Project',
                  params: { id: item.project.id },
                }"
                >{{ item.project.name }}</router-link
              >
              <span v-else>--</span>
            </td>
          </template>
          <template #time="{ item }">
            {{ formatTime(item.time) }}
          </template>
          <template #user="{ item }">
            <td>
              <router-link
                v-if="item.user"
                style="text-decoration: underline"
                :to="{
                  name: 'CompanyUser',
                  params: { user_id: item.user.id, id: item.company.id },
                }"
                >{{ item.user.full_name }}</router-link
              >
              <span v-else>--</span>
            </td>
          </template>
          <template #company="{ item }">
            <td>
              <router-link
                v-if="item.company"
                style="text-decoration: underline"
                :to="{ name: 'Company', params: { id: item.company.id } }"
                >{{ item.company.name }}</router-link
              >
              <span v-else>--</span>
            </td>
          </template>
        </SmartTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
